module.exports = {
  eventId: 'EiLzD7YNwa90QtlpfmOJ',
  accountId: 'rqPbvpK7wEHmEQ8QuyqS',
  stages: [
    'AGDbglalCRat6o3OmjGb',
    'EcqDv2hYd0GUJqnj7NFF',
    'yYedyv4iV9etaKHbjpUP',
    'qZSg7H7DvUNePRxtRyiT',
    'jcGZ4QFKtApxEy8iJlLe',
    'BVWINS1njRgurZUvZkpT',
  ],
  activateCertificate: '08 12 2021 16:00',
  liveEventId: '24db74af-3bcf-4aca-9e17-2ee39f828f6d',
};
